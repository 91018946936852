import { ReactElement, useEffect, useRef } from "react";
import { checkEngineEnabled } from "../../utils/checkEnableEngine";
import { LoginErrorCode } from "../../types";
import { useGetAccessToken, useSaveTokenToStorage } from "../../utils/token";
import { Box } from "@chakra-ui/react";
import { useSetSetting } from "../../utils/settings";
import { appAxios } from "../../utils/appAxios";
import { useLoginProcess } from "../../hooks/useLoginProcess";

export type CredentialResponseType = {
  credential: string;
};

type GoogleLoginButtonProps = {
  onInvalidUserInfo: (errorCode: LoginErrorCode) => void;
};

export function GoogleLoginButton({
  onInvalidUserInfo,
}: GoogleLoginButtonProps): ReactElement | null {
  const buttonRef = useRef<HTMLDivElement>(null);
  const gClientId = import.meta.env.VITE_APP_GCLOUD_CLIENT_ID;

  const getAccessToken = useGetAccessToken();
  const saveToken = useSaveTokenToStorage();
  const loginProcess = useLoginProcess(onInvalidUserInfo);
  const setSettingInfo = useSetSetting();

  useEffect(() => {
    const handleCredentialResponse = async (
      credentialResponse: CredentialResponseType
    ): Promise<void> => {
      const sendData = {
        credential: credentialResponse.credential,
      };
      const token = await getAccessToken(
        onInvalidUserInfo,
        undefined,
        sendData
      );
      if (!token) {
        return;
      }
      if (!checkEngineEnabled(token.access_token)) {
        onInvalidUserInfo("ENGINE_DISABLED");
        return;
      }
      saveToken(token);
      const employee_info = await appAxios.get("/employee/me");
      if (employee_info) {
        setSettingInfo(employee_info.data.employee_id);
      }
      loginProcess(token, employee_info.data.employee_id);
    };

    if (buttonRef.current && window.google) {
      window.google.accounts.id.initialize({
        client_id: gClientId,
        callback: handleCredentialResponse,
        ux_mode: "popup",
        context: "signin",
      });
      // window.google.accounts.id.prompt(() => {});
      window.google.accounts.id.renderButton(buttonRef.current, {
        text: "signin_with",
        type: "standard",
        size: "large",
        theme: "outline",
        logo_alignment: "left",
      });
    }
  }, [
    gClientId,
    getAccessToken,
    loginProcess,
    onInvalidUserInfo,
    saveToken,
    setSettingInfo,
  ]);

  if (!gClientId) {
    return null;
  }
  return (
    <Box minH="100px">
      <div ref={buttonRef} />
    </Box>
  );
}
